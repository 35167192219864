body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
  scroll-padding: 0px 0px 0px 0px;
  scrollbar-width: thin;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100vh;
  }
}

.slide-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.navContainer {
  &:hover {
    .navWrapper {
      span {
        opacity: 1;
      }
    }
  }
}
.navAllWrapper {
  transition: all 1s;
}
.navWrapper {
  display: flex;
  justify-content: space-evenly;
  z-index: 1;
  span {
    padding: 6px;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
  }
  span.disabledIcon {
    opacity: 0.3 !important;
    cursor: default !important;
  }
}
.fullscreen {
  // background-color: black;
}
.fullscreen.fullscreen-enabled {
  .slide-container {
    background: black;
  }
}

.videoElement {
  position: absolute;
  iframe {
    width: 100%;
    height: 100%;
    border: 0%;
  }
}

.customElement {
  position: absolute;
  // background-color: rgb(255 255 255);
  // outline: 1px solid white;
  overflow: auto;
  #tableauViz {
    justify-content: center;
    display: flex;
    transform: scale(1.0);
  }
}

.overlayShade {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background: rgb(0 0 0 / 68%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabluaeWrapper {
  max-width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 !important;
    flex: 1 auto;
    height: 100%;
}